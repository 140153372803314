import * as React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "../components/button"
import Container from "../components/container"
import Cta from "../components/cta"
import ExpandableContent from "../components/expandableContent"
import ExpandableContentSmall from "../components/expandableContentSmall"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TwoCards from "../components/twoCards"

// Assets
import manage from "../images/icons/manage.svg"
import persons from "../images/icons/persons.svg"
import virus from "../images/icons/virus.svg"

const UnderstandingCmv = () => {
  const data = useStaticQuery(graphql`
    query {
      ctaImage: file(relativePath: { eq: "what-is-cmv/cta.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 460
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      heroImage: file(relativePath: { eq: "what-is-cmv/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      smallImage: file(relativePath: { eq: "backgrounds/cta-small-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1440
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largeImage: file(relativePath: { eq: "backgrounds/cta-large-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  return (
    <Layout activeLinkIndex={0}>
      <Hero styles={tw`mb-0 pt-10 min-h-[200px] lg:(mb-0)`}>
        <Container>
          <div tw=" lg:(grid grid-cols-12)">
            <GatsbyImage
              image={getImage(data.heroImage)}
              css={[
                tw`inline-block float-right mt-6 -ml-8 max-w-[145px] lg:(mt-0 ml-0 float-none order-2 col-start-8 col-span-3 max-w-[225px]) xl:(max-w-[316px])`,
              ]}
              alt=""
            />
            <div tw="lg:(col-span-6 flex flex-col justify-center)">
              <h1 tw="font-light uppercase text-[32px] [line-height: 36px] mb-4 lg:(text-43 mb-2) xl:(text-6xl mb-6 [line-height: 1.4])">
                What is <span tw="font-extrabold">CMV?</span>
              </h1>
              <p tw="font-black text-base pr-32 lg:(text-xl pr-5 w-full) xl:(text-27)">
                And what does it mean for me or my loved one after a transplant?
              </p>
            </div>
          </div>
        </Container>
      </Hero>
      <section>
        <Container>
          <h2 tw="relative uppercase text-32 font-light mt-9 mb-5 [-webkit-text-fill-color: transparent] [-webkit-background-clip: text] bg-ctaText lg:(text-34) xl:(text-5xl mt-16 mb-10)">
            The <span tw="font-black">basics:</span>
          </h2>
          <div tw="lg:(grid grid-cols-3 gap-5) xl:(gap-10)">
            <div tw="pb-8 lg:(pb-9) xl:(pb-[60px])">
              <div tw="inline-block float-left w-[75px] lg:(float-none w-full h-[108px] mb-4) xl:(h-[152px])">
                <img
                  alt=""
                  src={virus}
                  css={[tw`w-14 lg:(mx-auto h-full w-auto) xl:(w-[135px])`]}
                />
              </div>
              <div>
                <p tw="font-light xl:(text-21)">
                  <span tw="block font-black text-xl py-4 lg:(inline font-semibold text-base pb-0) xl:(text-21)">
                    CMV{" "}
                  </span>
                  <span tw="lg:(font-semibold)">
                    is one of the most common post-transplant infections.
                  </span>{" "}
                  Most people have been exposed to it before, but the immune
                  system keeps it in check. When the immune system is weakened,
                  it may not be strong enough to hold back CMV. Luckily, there
                  are treatments that can help fight CMV.
                </p>
              </div>
            </div>
            <div tw="pb-8 lg:(pb-9) xl:(pb-[60px])">
              <div tw="inline-block float-left w-[75px] lg:(float-none w-full h-[108px] mb-4) xl:(h-[152px])">
                <img
                  alt=""
                  src={persons}
                  css={[
                    tw`w-14 py-4 lg:(py-0 mt-2.5 mx-auto h-[80%] w-auto) xl:(w-[120px])`,
                  ]}
                />
              </div>
              <div>
                <p tw="font-light mr-2.5 lg:(mr-0) xl:(text-21)">
                  <span tw="block font-black text-xl pt-4 pb-2.5 lg:(inline font-semibold text-base pb-0) xl:(text-21)">
                    As someone who had a transplant,{" "}
                  </span>
                  the medicines you may have been given could weaken your immune
                  system and leave you vulnerable to CMV infection and disease.
                </p>
              </div>
            </div>
            <div tw="pb-9 xl:(pb-[60px])">
              <div tw="inline-block float-left w-[75px] lg:(float-none w-full h-[108px] mb-4) xl:(h-[152px])">
                <img
                  alt=""
                  src={manage}
                  css={[tw`w-14 lg:(mx-auto h-[90%] w-auto) xl:(w-[130px])`]}
                />
              </div>
              <div>
                <p tw="font-light mr-2.5 lg:(mr-0) xl:(text-21)">
                  <span tw="block font-black text-xl pt-4 pb-5 lg:(inline font-semibold text-base) xl:(text-21)">
                    If you have active CMV,{" "}
                  </span>
                  your doctor might give you antiviral medicines to manage it.
                  Your doctor will keep track of your progress by testing the
                  level of virus in your blood as needed.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {/* lilac background begins */}
      <div tw="bg-lilacGradient mt-32">
        <div tw="relative -top-32 -mb-32">
          <ExpandableContent />
        </div>
        <section tw="pb-1 lg:(pb-16) xl:(pb-24)">
          <Container>
            <h2 tw="relative uppercase text-32 font-light mt-9 mb-3 [-webkit-text-fill-color: transparent] [-webkit-background-clip: text] bg-ctaText lg:(text-34 mb-6 mt-14) xl:(text-5xl)">
              CMV and <span tw="font-black">my transplant</span>
            </h2>
            <p tw="uppercase font-light mb-5 lg:(text-lg) xl:(text-2xl)">
              choose yours
            </p>
          </Container>
          <ExpandableContentSmall />
        </section>
      </div>
      <section tw="py-10 mb-4 xl:(pt-24)">
        <TwoCards />
      </section>
      <section>
        <Cta
          data={data}
          styles={tw`min-h-0 lg:(mt-14 mb-10 pb-0) xl:(mt-28 mb-24 pt-0)`}
        >
          <Container tw="relative lg:(flex flex-row-reverse px-14) xl:(pl-36 min-h-[362px])">
            <div tw="min-h-[210px] flex flex-col justify-center lg:(justify-center w-1/2 pl-2 relative -top-6) xl:(ml-24 top-0)">
              <p tw="mr-36 mb-[3.25rem] font-black text-[28px] leading-9 lg:(text-xl font-semibold mr-10 mb-5) xl:(text-32 mb-11)">
                Ready to learn about <span tw="uppercase">Livtencity</span>?
              </p>
              <div tw="text-center lg:(text-left ml-2) xl:(ml-3)">
                <Button link="/what-is-livtencity/" buttonClass="gtm-cta-nav">
                  Tell me more
                </Button>
              </div>
            </div>
            <GatsbyImage
              image={getImage(data.ctaImage)}
              css={[
                tw`transform scale-x-[-1] absolute [z-index: -1] -right-7 -top-[90px] max-w-[183px] lg:(transform-none left-10 -top-[132px] max-w-[310px]) xl:(max-w-[460px] flex-none)`,
              ]}
              objectFit="cover"
              objectPosition="top center"
              alt=""
            />
          </Container>
        </Cta>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Understanding CMV after Transplant | LIVTENCITY® (maribavir)"
    description="Learn about cytomegalovirus (CMV) and what it could mean for you or your loved one after transplant. See full Safety and Patient Information."
  />
)

export default UnderstandingCmv
