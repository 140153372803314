import * as React from "react"
import "twin.macro"
import { ImageQuery } from "./imageQuery"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../components/button"
import Container from "./container"

import talkBubbles from "../images/icons/talk-bubbles.svg"
import cmv from "../images/icons/cmv.svg"

const TwoCards = () => {
  const data = useStaticQuery(graphql`
    query {
      smallImage: file(
        relativePath: { eq: "backgrounds/two-cards-violet-small.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1024
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largeImage: file(
        relativePath: { eq: "backgrounds/two-cards-violet-large.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 700
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  return (
    <Container>
      <div tw="flex flex-wrap -mx-[16px] lg:mx-auto justify-between">
        <div
          tw="pt-16 pb-14 px-11 text-center rounded-md mb-8
    bg-gradient-to-tr from-[#F2ECFF] to-[#D8C7FF] font-extralight w-full [clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 30px, calc(100% - 30px) 0)] 
    lg:(w-[calc(50% - 8px)] font-semibold text-xl px-7 pt-12 pb-11 mb-0 [clip-path: polygon(45px 0, 100% 0, 100% 100%, 0 100%, 0 45px)])
    xl:(text-27 w-[calc(50% - 11px)] [clip-path: polygon(63px 0, 100% 0, 100% 100%, 0 100%, 0 63px)])"
        >
          <img alt="" tw="mx-auto mb-6 max-h-[105px]" src={talkBubbles} />
          <p tw="font-light xl:(text-21 leading-10)">
            <span tw="font-black text-xl inline-block mb-1 lg:(font-bold inline) xl:(text-27)">
              Remember: you might have questions or concerns during this
              process.{" "}
            </span>
            <br />
            If you do, be sure to always talk to your transplant team.
          </p>
        </div>
        <div
          tw="text-white text-center font-black rounded-md relative w-full py-16 px-4 [clip-path: polygon(30px 0, 100% 0, 100% 100%, 0 100%, 0 30px)] 
      lg:(w-[calc(50% - 8px)] px-7 py-11 font-semibold [clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 45px, calc(100% - 45px) 0)]) 
      xl:(w-[calc(50% - 11px)] [clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 63px, calc(100% - 63px) 0)])"
        >
          <ImageQuery
            alt=""
            data={data}
            objectFit="cover"
            objectPosition="top left"
            style={{
              width: "100%",
              borderRadius: "inherit",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
          ></ImageQuery>
          <img alt="" tw="mx-auto mb-6" src={cmv} />
          <p tw="text-xl mb-5 xl:text-27">
            Need help talking about
            <br />
            your treatment plan?
          </p>
          <Button
            twoLine
            link="/pdf/tips-for-talking-with-your-transplant-team.pdf"
            external
            buttonClass="gtm-download"
            documentName="DoctorDiscussionGuide"
          >
            Explore Tips for Talking <br />
            with your Transplant Team
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default TwoCards
