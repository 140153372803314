import React, { useState } from "react"
import tw from "twin.macro"
import { ImageQuery } from "./imageQuery"
import { useStaticQuery, graphql } from "gatsby"
import Container from "./container"
import chevron from "../images/icons/chevron.svg"

const ExpandableContent = () => {
  const [expanded, setExpanded] = useState(false)
  const handleClick = () => {
    setExpanded(prev => !prev)
  }
  const data = useStaticQuery(graphql`
    query {
      smallImage: file(
        relativePath: { eq: "backgrounds/expand-content-small.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1024
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largeImage: file(
        relativePath: { eq: "backgrounds/expand-content-large.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1440
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  const content = {
    tabId: "what-are-cmv-button",
    contentId: "what-are-cmv-content",
    buttonTitle: "WhatAreCmv",
    header: (
      <>
        What are <span tw="font-black">Refractory CMV</span>{" "}
        <br tw="hidden lg:inline-block" />
        and <span tw="font-black">Resistant CMV?</span>
      </>
    ),
    copy: (
      <>
        <p tw="pb-6 lg:(pb-2) xl:(pb-4)">
          <span tw="font-semibold">Refractory CMV</span> is diagnosed when a lab
          test shows the levels of CMV virus in the blood has not adequately
          decreased after 2 weeks of treatment.
        </p>
        <p tw="pb-7 lg:(pb-6) xl:(pb-4)">
          In addition, when a lab test shows a type of CMV with a gene mutation
          known to prevent the medication from working, that is considered{" "}
          <span tw="font-semibold">resistant CMV.</span>
        </p>
        <p tw="pb-6 font-semibold xl:(pb-10)">
          If you have refractory or resistant CMV, your transplant team may then
          give you a different antiviral medicine in order to help fight the
          infection.
        </p>
      </>
    ),
  }

  return (
    <section>
      <Container>
        <div
          tw="-mx-[16px] [clip-path: polygon(30px 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0 30px)]
      lg:(mx-auto [clip-path: polygon(45px 0, 100% 0, 100% calc(100% - 45px), calc(100% - 45px) 100%, 0 100%, 0 45px)])
      xl:([clip-path: polygon(63px 0, 100% 0, 100% calc(100% - 63px), calc(100% - 63px) 100%, 0 100%, 0 63px)])"
        >
          <div
            tw="text-white font-light rounded-md relative w-full px-4
          lg:(px-14 pt-12 pb-1.5)
          xl:(pt-20 pb-2.5 pl-[5.5rem] pr-[6.5rem])"
          >
            <ImageQuery
              alt=""
              data={data}
              objectFit="cover"
              objectPosition="top left"
              style={{
                width: "100%",
                borderTopRightRadius: "inherit",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: "-1",
                pointerEvents: "none",
              }}
            ></ImageQuery>
            <h2 tw="text-32 uppercase max-w-[500px] pt-11 pb-6 lg:(max-w-full text-34 pt-0) xl:(text-5xl leading-tight pb-10)">
              {content.header}
            </h2>
            <div
              css={[
                tw`h-0 overflow-hidden invisible xl:(text-21)`,
                expanded && tw`h-auto visible`,
              ]}
              id={content.contentId}
              aria-labelledby={content.tabId}
            >
              {content.copy}
            </div>
          </div>
          <button
            css={[
              tw`bg-coral border-coral text-white border-2 shadow-button font-black antialiased uppercase pt-3.5 pb-2 w-full rounded-bl-md tracking-[0.125em] lg:(text-lg pb-4 focus:(outline outline-white outline-offset-[-3px] outline-2)) xl:(text-2xl)`,
            ]}
            onClick={handleClick}
            aria-expanded={expanded ? true : false}
            aria-controls={content.contentId}
            id={content.tabId}
            aria-label="expand content"
            aria-flowto={content.contentId}
            className="gtm-expand-button"
            data-title={"CTA" + content.buttonTitle}
          >
            <span tw="pointer-events-none lg:(relative top-0 transition-all ease-in-out duration-300 hover:(-top-1))">
              {!expanded && <>expand</>}
              {expanded && <>collapse</>}
              <span tw="inline-block ml-1.5 h-4 xl:(h-5 mb-0.5)">
                <img
                  alt=""
                  src={chevron}
                  css={[
                    tw`h-full w-full transition-all`,
                    expanded && tw`scale-y-[-1] relative top-1.5`,
                  ]}
                />
              </span>
            </span>
          </button>
        </div>
      </Container>
    </section>
  )
}

export default ExpandableContent
